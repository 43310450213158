.content-container-selection-input{
    padding: 15px 10% 15px 10%;
    background-color: #F7FAFC;
    width: 100%;
    margin: 10px 0 10px;
    min-height: 126px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .table-container{
        margin: 10px 10%;
        overflow: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    
    .table-container::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    
    .title {
        font-size: 18px;
        margin-left: 10%;
        margin-bottom: 30px;
    }
    
    .input-text{
        background-color: white;
    }
    
    .input-number{
        width: 100%;
        outline: 2px solid transparent;
        outline-offset: 2px;
        height: 40px;
        border-radius: 6px;
        padding: 0 16px;
        border: 1px solid;
        border-color: inherit;
        text-align: end;
    }
    
    .input-label-selection{
        color: #718096;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    
        span{
            color: #38B2AC;
        }
    }
    
    .selected{
        display: flex;
        background-color: #4FD1C5;
        justify-content: space-between;
    
    }
}

