.net-benefit-estimator-container {
    width: 100%;
    font-family: Helvetica, Arial, sans-serif;
    
    .content-container{
        padding: 10px 10% 20px 10%;
        background-color: #F7FAFC;

        
    }

    .table-container{
        margin: 10px 10%;
        overflow: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */

        .css-xumdn4, .css-1yjbv7j, .css-1zts0j{
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    .table-container::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    .title {
        font-size: 18px;
        margin-left: 10%;
        margin-bottom: 30px;
    }
    
    .input-text{
        background-color: white;
    }

    .input-number{
        width: 100%;
        outline: 2px solid transparent;
        outline-offset: 2px;
        height: 40px;
        border-radius: 6px;
        padding: 0 16px;
        border: 1px solid;
        border-color: inherit;
        text-align: end;
        margin-top: 5px;
    }

    .input-label{
        color: #718096;
        font-size: 14px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        span{
            color: #38B2AC;
        }
    }

    .selected{
        display: flex;
        background-color: #4FD1C5;
        justify-content: space-between;

    }
}