.historic-analysis-content-container{
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;

    .first-row{
        flex-direction: column;
        width: 50%;
        margin-right: 15px;

        .css-xumdn4{
            padding-top: 8px;
            padding-bottom: 8px;
        }

        .css-jh02fl{
            padding-top: 8px;
            padding-bottom: 8px;
        }
        
    }

    .second-row{
        flex-direction: column;
        width: 50%;
        margin-left: 15px;
    }

    @media (max-width: 750px) {
        flex-direction: column;

        .first-row, .second-row{
            width: 100%;
        }

        .first-row {
            margin-bottom: 50px;
        }
    }
}