.coverage-analysis-content-container{
    display: flex;
    flex-direction: row;
    width: 80%;
    margin:auto;

    .first-row{
        flex-direction: column;
        width: 50%;
        margin-right: 20px;
    
        td{
            max-width: 120px;
        }
        .css-xumdn4{
            padding-top: 8px;
            padding-bottom: 8px;
        }

        .css-1zts0j{
            padding-bottom: 8px;
            padding-top: 8px;
        }
    }
    
    .second-row{
        flex-direction: column;
        width: 50%;
        margin-left: 20px;
    }
    
    .with-plus, .with-equals{
        position: relative;
    }
    
    .with-equals::after, .with-plus::after {
        position: absolute;
        content: " +";
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .with-equals::after{
        content: " =";
    }

    @media (max-width: 750px) {
        flex-direction: column;

        .first-row, .second-row{
            width: 100%;
        }

        .first-row {
            margin-bottom: 50px;
        }
    }
}