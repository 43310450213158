.coverage-container {
    margin-bottom: 50px;

    .title {
        font-size: 18px;
    }

    .css-xumdn4{
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .css-1zts0j{
        padding-bottom: 8px;
        padding-top: 8px;
    }

    .coverage-data {
        height: 126px;
        margin-top: 32px;
        margin-bottom: 30px;
        padding: 20px 10vw;
        align-items: center;
        justify-content: space-between;

        .grid-item {
            flex-direction: column;
            font-size: 14px;
            width: 22vw;
            margin-left: 10px;

            .item-line {
                margin-bottom: 11px;
                justify-content: space-between;                
            }

            .numbers {
                font-weight: 700;
            }
        }
    }
}

.table-header {
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .inputs {
        flex-direction: column;
    }
}